import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './components/Home';
import Shop from './components/Shop';
import Contact from './components/Contact';
import Blog from './components/Blog';
import FAQ from './components/FAQ';
import Header from './components/Header';
import Footer from './components/Footer';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import Education from './components/Education';
import AdminDashboard from './components/AdminDashboard';
import AdminLogin from './components/AdminLogin';
import CouponModal from './components/CouponModal';
import { CartProvider } from './context/CartContext';

function App() {
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isCouponVisible, setIsCouponVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check for first visit to show the coupon modal
  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit');
    if (!firstVisit) {
      setIsCouponVisible(true);
      localStorage.setItem('firstVisit', 'true');
    }

    // Check if user is already authenticated
    const authStatus = localStorage.getItem('isAdminAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  // Function to handle successful login
  const handleLogin = (isLoggedIn) => {
    if (isLoggedIn) {
      setIsAuthenticated(true);
      localStorage.setItem('isAdminAuthenticated', 'true');
    } else {
      console.error('Login failed. Please try again.');
    }
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAdminAuthenticated');
    localStorage.removeItem('admin_id');
    localStorage.removeItem('role');
  };

  // Protected Route component
  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/admin/login" />;
    }
    return children;
  };

  const openCart = () => {
    setIsCartVisible(true);
  };

  const closeCart = () => {
    setIsCartVisible(false);
  };

  const handleBackToCart = () => {
    setIsCartVisible(true);
  };


  return (
    <HelmetProvider>
      <CartProvider>
        <Router>
          <div className="App">
            {isCouponVisible && (
              <CouponModal
                onClose={() => setIsCouponVisible(false)}
                onSuccess={() => setIsCouponVisible(false)}
              />
            )}
            <Header openCart={openCart} />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/education" element={<Education />} />

              {/* Admin Routes */}
              <Route
                path="/admin/login"
                element={
                  isAuthenticated ? (
                    <Navigate to="/admin/dashboard" />
                  ) : (
                    <AdminLogin onLogin={handleLogin} />
                  )
                }
              />
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRoute>
                    <AdminDashboard onLogout={handleLogout} />
                  </ProtectedRoute>
                }
              />

              {/* Cart and Checkout Routes */}
              <Route
                path="/cart"
                element={<Cart onClose={closeCart} handleBackToCart={handleBackToCart} />}
              />

              <Route
                path="/checkout"
                element={<Checkout onClose={closeCart} handleBackToCart={handleBackToCart} />}
              />

            </Routes>

            <Footer />
          </div>
        </Router>
      </CartProvider>
    </HelmetProvider>
  );
}

export default App;