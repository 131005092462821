import React from 'react';
import { X } from 'lucide-react';

const BlogPostModal = ({ post, onClose }) => {
  if (!post) return null;

  const blogContent = {
    "Why Humidity Matters: Keeping Ingredients Fresh": {
      content: `
      <p>The science behind freshness is more intricate than you might think. At room temperature, most dried herbs and spices lose their potency at an alarming rate, with humidity being the primary culprit. Our extensive research shows that controlling humidity can extend shelf life by up to 300%.</p>

      <h3>Understanding Humidity's Impact</h3>
      <p>Humidity levels between 30-40% create the ideal environment for dried herbs and spices. Below this range, essential oils evaporate rapidly, while higher levels risk mold growth. This delicate balance is why traditional storage methods often fall short.</p>

      <h3>The HydroLid Difference</h3>
      <p>Our two-way humidity regulation system maintains optimal moisture levels through:
        <ul>
          <li>Micro-perforated moisture barriers that allow excess humidity to escape</li>
          <li>Food-grade silica gel chambers that absorb and release moisture as needed</li>
          <li>Airtight sealing that prevents external humidity fluctuations</li>
        </ul>
      </p>

      <h3>Best Practices for Storage</h3>
      <p>Maximize your HydroLid's effectiveness with these tips:
        <ul>
          <li>Keep containers away from direct sunlight and heat sources</li>
          <li>Store at consistent room temperature (65-75°F)</li>
          <li>Clean and completely dry containers before use</li>
          <li>Replace the humidity control insert every 6-12 months</li>
        </ul>
      </p>`,
      author: "Dr. Sarah Chen",
      readTime: "5 min read",
      date: "March 15, 2024"
    },
    "The Essential Guide to Herbal Storage": {
      content: `
      <p>Whether you're a culinary enthusiast or wellness practitioner, proper herb storage is crucial for maintaining therapeutic and flavor properties. Our research with leading herbalists has unveiled optimal storage practices that preserve the essence of your herbs.</p>

      <h3>Different Herbs, Different Needs</h3>
      <p>Herbs fall into three main categories, each requiring specific storage conditions:
        <ul>
          <li>Leafy Herbs (basil, mint): Most sensitive to moisture fluctuations</li>
          <li>Woody Herbs (rosemary, thyme): More resilient but require consistent conditions</li>
          <li>Seeds/Pods (fennel, cardamom): Need protection from light and air exposure</li>
        </ul>
      </p>

      <h3>Common Storage Mistakes</h3>
      <p>Avoid these critical errors that compromise herb quality:
        <ul>
          <li>Using plastic containers that trap moisture</li>
          <li>Storing different herbs together</li>
          <li>Keeping herbs in areas with temperature fluctuations</li>
          <li>Neglecting to check for moisture buildup</li>
        </ul>
      </p>

      <h3>The Science of Preservation</h3>
      <p>Our studies show that controlling these four factors extends herb life significantly:
        <ul>
          <li>Light exposure (reduced by 95% with amber glass)</li>
          <li>Oxygen contact (minimized through airtight sealing)</li>
          <li>Temperature stability (maintained at 68°F ±2°)</li>
          <li>Humidity regulation (kept at 35% using HydroLid technology)</li>
        </ul>
      </p>`,
      author: "Michael Torres",
      readTime: "6 min read",
      date: "March 18, 2024"
    },
    "Sustainable Living with Mellow Heights": {
      content: `
      <p>Sustainability isn't just about products—it's about creating a lasting impact through mindful choices. At Mellow Heights, we've developed solutions that align with both environmental consciousness and practical living.</p>

      <h3>Our Environmental Impact</h3>
      <p>Since our founding, we've achieved:
        <ul>
          <li>45% reduction in carbon footprint through local manufacturing</li>
          <li>100% recyclable packaging materials</li>
          <li>Zero-waste production process</li>
          <li>Support for sustainable herb farming practices</li>
        </ul>
      </p>

      <h3>Beyond the Product</h3>
      <p>HydroLid's impact extends beyond storage:
        <ul>
          <li>Reduces food waste by extending herb life up to 3 weeks</li>
          <li>Eliminates need for plastic storage containers</li>
          <li>Supports local herb growers through our partnership program</li>
          <li>Encourages sustainable harvesting practices</li>
        </ul>
      </p>

      <h3>Community Initiatives</h3>
      <p>Join our sustainability movement:
        <ul>
          <li>Monthly workshops on sustainable living</li>
          <li>Community herb garden program</li>
          <li>Recycling program for old storage containers</li>
          <li>Educational resources for sustainable practices</li>
        </ul>
      </p>`,
      author: "Emma Green",
      readTime: "4 min read",
      date: "March 20, 2024"
    }
  };

  const currentPost = blogContent[post.title];

  return (
    <div className="blog-modal-overlay">
      <div className="blog-modal">
        <header className="blog-modal-header">
          <h2>{post.title}</h2>
          <button onClick={onClose} className="close-button">
            <X size={24} />
          </button>
        </header>
        <div className="blog-modal-content">
          <div className="blog-meta">
            <span className="blog-author">{currentPost.author}</span>
            <span className="blog-date">{currentPost.date}</span>
            <span className="blog-read-time">{currentPost.readTime}</span>
          </div>
          <div 
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: currentPost.content }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogPostModal;