import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAuthTimer = (timeout = 600000) => { // 10 minutes = 600000ms
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const events = ['mousedown', 'keydown', 'scroll', 'mousemove'];
    
    const checkInactivity = () => {
      if (Date.now() - lastActivity >= timeout) {
        localStorage.removeItem('isAdminAuthenticated');
        localStorage.removeItem('admin_id');
        localStorage.removeItem('role');
        window.location.href = '/admin/login';
      }
    };

    const interval = setInterval(checkInactivity, 1000);
    const updateActivity = () => setLastActivity(Date.now());

    events.forEach(event => {
      document.addEventListener(event, updateActivity);
    });

    return () => {
      clearInterval(interval);
      events.forEach(event => {
        document.removeEventListener(event, updateActivity);
      });
    };
  }, [lastActivity, timeout]);
};

export default useAuthTimer;