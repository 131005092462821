import React, { useContext, useState, useRef, useEffect } from 'react';
import '../stylings/Cart.css';
import { CartContext } from '../context/CartContext';
import Checkout from './Checkout';
import { products } from './Shop';

const Cart = ({ onClose }) => {
  const { cartItems, removeFromCart, updateQuantity, addToCart, totalAmount, totalAmountWithShipping } = useContext(CartContext);
  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
  const carouselRef = useRef(null);
  const clonedProducts = [...products, ...products, ...products];

  // Calculate totals and fees
  const subtotal = totalAmount;
  const shippingFee = cartItems.length > 0 ? 5.99 : 0;
  const processingFee = (subtotal + shippingFee) * 0.05;
  const finalTotal = subtotal + shippingFee + processingFee;

  // Aggregate quantity of standalone HydroLid units in the cart regardless of color
  const totalStandaloneLids = cartItems
    .filter(item => item.title.toLowerCase() === 'standalone hydrolid')
    .reduce((sum, item) => sum + item.quantity, 0);

  const standaloneLidPrice = products.find(
    product => product.title.toLowerCase() === 'standalone hydrolid'
  )?.price;

  const threePackHydroLid = products.find(
    product => product.title.toLowerCase() === '3-pack standalone hydrolid'
  );

  // Show savings tip if there are 3 or more standalone lids in the cart, regardless of color
  const showSavingsTip = totalStandaloneLids >= 3;

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const resetScroll = () => {
        if (carousel.scrollLeft <= 0) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        } else if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        }
      };

      carousel.addEventListener('scroll', resetScroll);

      // Initialize to the middle section
      carousel.scrollLeft = carousel.scrollWidth / 3;

      return () => {
        carousel.removeEventListener('scroll', resetScroll);
      };
    }
  }, []);

  // Increment item quantity
  const handleIncrement = (itemId, color) => {
    const item = cartItems.find((item) => item.id === itemId && item.color === color);
    if (item) {
      updateQuantity(itemId, color, item.quantity + 1);
    }
  };

  // Decrement item quantity
  const handleDecrement = (itemId, color) => {
    const item = cartItems.find((item) => item.id === itemId && item.color === color);
    if (item && item.quantity > 1) {
      updateQuantity(itemId, color, item.quantity - 1);
    }
  };

  // Open checkout modal
  const handleCheckout = () => {
    setIsCheckoutVisible(true);
  };

  // Close checkout modal
  const closeCheckout = () => {
    setIsCheckoutVisible(false);
    onClose();
  };

  // Handle back to cart functionality
  const handleBackToCartInternal = () => {
    setIsCheckoutVisible(false);
  };

  return (
    <div className="cart-modal-overlay">
      <div className="cart-modal">
        {/* Close Button */}
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>

        {/* Cart Content */}
        <h2 className="cart-title">Your Cart</h2>

        {/* Scrollable Cart Items Container */}
        <div className="cart-items-container">
            {cartItems.length === 0 ? (
              <div className="empty-cart">
                <p className="empty-cart-text">Your cart is currently empty. Let's fix that!</p>
                <a href="/shop" className="shop-link">Shop Now</a>
              </div>
            ) : (
            cartItems.map((item) => (
              <div key={`${item.id}-${item.color}`} className="cart-item">
                <div className="cart-item-main">
                  <img src={item.image} alt={item.title} className="cart-item-img" />
                  <div className="cart-item-details">
                    <div className="cart-item-header">
                      <h3 className="cart-item-title">{item.title} {item.color ? `(${item.color})` : ''}</h3>
                      <button className="remove-btn" onClick={() => removeFromCart(item.id, item.color)}>
                        Remove
                      </button>
                    </div>
                    <div className="cart-item-price-container">
                      <div className="cart-item-controls">
                        <p className="cart-item-price">
                          ${item.price.toFixed(2)} × {item.quantity}
                        </p>
                        <div className="cart-item-quantity">
                          <button className="quantity-btn" onClick={() => handleDecrement(item.id, item.color)}>
                            -
                          </button>
                          <span className="quantity">{item.quantity}</span>
                          <button className="quantity-btn" onClick={() => handleIncrement(item.id, item.color)}>
                            +
                          </button>
                        </div>
                      </div>
                      <span className="item-total">${(item.price * item.quantity).toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Price Tip */}
        {showSavingsTip && threePackHydroLid && (
          <div className="price-tip">
            <p>Save money by buying our <strong>3-Pack Standalone HydroLid</strong>!</p>
            <p>
              You'll save <strong>${((totalStandaloneLids * standaloneLidPrice) - threePackHydroLid.price).toFixed(2)}</strong> compared to buying 3 standalone lids separately.
            </p>
            <button className="add-three-pack-btn" onClick={() => addToCart(threePackHydroLid)}>
              Add 3-Pack for ${threePackHydroLid.price.toFixed(2)}
            </button>
          </div>
        )}

        {/* Enhanced Receipt Section */}
        <div className="receipt-container">
          <h3 className="receipt-title">Order Summary</h3>
          <div className="receipt-line">
            <span>Merchandise Subtotal:</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
          {cartItems.length > 0 && (
            <div className="receipt-line">
              <span>Shipping & Handling:</span>
              <span>${shippingFee.toFixed(2)}</span>
            </div>
          )}
          <div className="receipt-line receipt-subtitle">
            <span>Subtotal before fees:</span>
            <span>${(subtotal + shippingFee).toFixed(2)}</span>
          </div>
          {cartItems.length > 0 && (
            <div className="receipt-line fee">
              <span>Processing Fee (5%):</span>
              <span>${processingFee.toFixed(2)}</span>
            </div>
          )}
          <div className="receipt-line total">
            <span>Total:</span>
            <span>${finalTotal.toFixed(2)}</span>
          </div>
        </div>

        {/* Checkout Button */}
        <button 
          className="checkout-btn" 
          onClick={handleCheckout}
          disabled={cartItems.length === 0}
        >
          {cartItems.length === 0 ? 'Cart is Empty' : 'Proceed to Checkout'}
        </button>

        {/* Checkout Modal */}
        {isCheckoutVisible && (
          <Checkout 
            cartItems={cartItems} 
            subtotal={subtotal}
            shippingFee={shippingFee}
            processingFee={processingFee}
            total={finalTotal}
            onClose={closeCheckout}
            handleBackToCart={handleBackToCartInternal}
          />
        )}

        {/* Product Carousel */}
        <div className="cart-suggestions-container">
          <h3 className="cart-suggestions-heading">You May Also Like</h3>
          <div className="cart-suggestions-carousel" ref={carouselRef}>
            {clonedProducts.map((product, index) => (
              <div key={`${product.id}-${index}`} className="cart-suggestion-card">
                <div className="cart-suggestion-image-wrapper">
                  <img src={product.image} alt={product.title} className="cart-suggestion-image" />
                </div>
                <div className="cart-suggestion-content">
                  <h4 className="cart-suggestion-title">{product.title}</h4>
                  <p className="cart-suggestion-price">${product.price.toFixed(2)}</p>
                  <button
                    className="cart-suggestion-add-btn"
                    onClick={() => addToCart({ ...product, quantity: 1 })}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;