import React, { useState } from 'react';
import '../stylings/CouponModal.css';

const CouponModal = ({ onClose, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        'https://cakvx0v4ng.execute-api.us-east-1.amazonaws.com/default/coupon_validate_email',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Check if response contains JWT token
        if (data.token) {
          console.log('JWT Token:', data.token); // For development purposes
          setMessage('Check your email for a 15% discount code!');
          onSuccess();
        } else {
          setMessage(data.message || 'An error occurred.');
        }
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Failed to send discount code.');
      }
    } catch (error) {
      setMessage('Failed to send discount code. Please try again later.');
    }

    setLoading(false);
  };

  return (
    <div className="coupon-modal-overlay">
      <div className="coupon-modal">
        <button className="coupon-modal-close" onClick={onClose}>
          &times;
        </button>
        <h2 className="coupon-modal-header">Get 15% Off Your First Order!</h2>
        <p className="coupon-modal-text">Enter your email to receive your discount code.</p>
        <form onSubmit={handleSubmit} className="coupon-modal-input-container">
          <input
            type="email"
            className="coupon-modal-input"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            type="submit"
            className="coupon-modal-button"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Get Discount'}
          </button>
        </form>
        {message && <p className="coupon-modal-text">{message}</p>}
      </div>
    </div>
  );
};

export default CouponModal;
