import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../stylings/Header.css';
import logo from '../imgs/mellowHeightsLogo_final_Nowords.png';
import cartIcon from '../icons/mellowCart_ICON.png';
import { CartContext } from '../context/CartContext';
import Cart from './Cart';

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [scrollToAbout, setScrollToAbout] = useState(false);
  const { cartItems } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if user is authenticated and if we're on an admin page
  const isAuthenticated = localStorage.getItem('isAdminAuthenticated') === 'true';
  const isAdminPage = location.pathname.startsWith('/admin');

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const toggleCart = () => {
    setIsCartVisible((prev) => !prev);
  };

  // Handle admin logout
  const handleLogout = () => {
    localStorage.removeItem('isAdminAuthenticated');
    navigate('/');
    setIsDropdownVisible(false);
  };

  // Calculate total items in the cart
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  // Scroll to the About section if on the Home page
  useEffect(() => {
    if (scrollToAbout && location.pathname === '/') {
      const aboutSection = document.getElementById('about-section');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
        setScrollToAbout(false);
      }
    }
  }, [location.pathname, scrollToAbout]);

  const handleAboutClick = () => {
    if (location.pathname === '/') {
      const aboutSection = document.getElementById('about-section');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setScrollToAbout(true);
      navigate('/');
    }
    setIsDropdownVisible(false);
  };

  const handleShopClick = () => {
    navigate('/shop');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsDropdownVisible(false);
  };

  // Prevent text selection and address detection
  const preventSelection = (e) => {
    e.preventDefault();
    return false;
  };

  // Handle logo click with prevention
  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <>
      <header 
        className="header-container" 
        onDoubleClick={preventSelection}
      >
        {/* Hamburger Menu */}
        <div
          className={`hamburger-menu ${isDropdownVisible ? 'open' : ''}`}
          onClick={toggleDropdown}
          onDoubleClick={preventSelection}
        >
          <div className="bar bar1"></div>
          <div className="bar bar2"></div>
          <div className="bar bar3"></div>
        </div>

        {/* Mellow Text */}
        <div 
          className="header-text header-text-left"
          onDoubleClick={preventSelection}
        >
          Mellow
        </div>

        {/* Logo */}
        <div 
          className="logo"
          onClick={handleLogoClick}
          onDoubleClick={preventSelection}
        >
          <Link 
            to="/"
            onClick={(e) => e.preventDefault()}
            onDoubleClick={preventSelection}
          >
            <img 
              src={logo} 
              alt="Mellow Heights Logo" 
              className="logo-img" 
              draggable="false"
              onDoubleClick={preventSelection}
            />
          </Link>
        </div>

        {/* Heights Text */}
        <div 
          className="header-text header-text-right"
          onDoubleClick={preventSelection}
        >
          Heights
        </div>

        {/* Cart Icon with Item Count - Hidden on admin pages */}
        {!isAdminPage && (
          <div 
            className="cart-icon" 
            onClick={toggleCart}
            onDoubleClick={preventSelection}
          >
            {totalItems > 0 && (
              <div className="cart-item-count">{totalItems}</div>
            )}
            <img 
              src={cartIcon} 
              alt="Cart Icon" 
              className="cart-img" 
              draggable="false"
              onDoubleClick={preventSelection}
            />
          </div>
        )}

        {/* Dropdown Menu */}
        {isDropdownVisible && (
          <div 
            className="dropdown-menu visible"
            onDoubleClick={preventSelection}
          >
            <Link to="/" className="dropdown-link" onClick={toggleDropdown}>Home</Link>
            <Link to="/shop" className="dropdown-link" onClick={handleShopClick}>Shop</Link>
            <Link to="/" className="dropdown-link" onClick={handleAboutClick}>About</Link>
            <Link to="/education" className="dropdown-link" onClick={toggleDropdown}>Education</Link>
            {/*<Link to="/blog" className="dropdown-link" onClick={toggleDropdown}>Blog</Link>*/}
            <Link to="/faq" className="dropdown-link" onClick={toggleDropdown}>FAQ</Link>
            <Link to="/contact" className="dropdown-link" onClick={toggleDropdown}>Contact</Link>

            {/* Admin-specific menu items */}
            {isAuthenticated && (
              <>
                <div className="dropdown-divider"></div>
                <Link to="/admin/dashboard" className="dropdown-link admin-link" onClick={toggleDropdown}>
                  Dashboard
                </Link>
                <button className="dropdown-link logout-button" onClick={handleLogout}>
                  Logout
                </button>
              </>
            )}
          </div>
        )}
      </header>

      {/* Cart Modal */}
      {isCartVisible && <Cart onClose={toggleCart} />}
    </>
  );
};

export default Header;