import React, { useState, useContext, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import html2canvas from 'html2canvas';
import '../stylings/Checkout.css';
import { CartContext } from '../context/CartContext';
import printerIcon from '../icons/mellowPrinter_ICON.png';

const stripePromise = loadStripe('pk_live_51QDsEsBQZ5LwZWDXhxl15vcqFQoqa53Oy8jW57UDEh8uE58e4gfJYM6sKebk7kv73nzj3np7VGUuGXxl3HzfULgU00rFSQSTds');

const Checkout = ({
  cartItems,
  subtotal,
  shippingFee,
  processingFee,
  total,
  onClose,
  handleBackToCart = () => {} // Provide default empty function
}) => {
  const { clearCart } = useContext(CartContext);

  // Simplified back to cart handling
  const handleBackToCartClick = (e) => {
    e.preventDefault();
    handleBackToCart();
  };

  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    zip: '',
    subscribe: false,
  });
  const [discountCode, setDiscountCode] = useState('');
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const receiptRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCustomerInfo({
      ...customerInfo,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const sendEmailReceipt = async (customerEmail, customerName) => {
    try {
      const emailResponse = await fetch('https://cakvx0v4ng.execute-api.us-east-1.amazonaws.com/default/mellowStripePaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: customerEmail,
          name: customerName,
          cartItems,
          total: discountApplied ? total - discountAmount : total,
        }),
      });

      if (!emailResponse.ok) {
        console.error("Failed to send email receipt:", await emailResponse.json());
      } else {
        console.log("Email receipt sent successfully.");
      }
    } catch (error) {
      console.error("Error sending email receipt:", error);
    }
  };

  const validateDiscountCode = async () => {
    try {
      const response = await fetch('https://cakvx0v4ng.execute-api.us-east-1.amazonaws.com/default/validateDiscountCode', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: discountCode }),
      });
      const data = await response.json();

      if (data.success) {
        // Apply discount only to eligible items (excluding "3-Pack Standalone HydroLid")
        const discountableItems = cartItems.filter(item => !item.title.toLowerCase().includes('3-pack standalone hydrolid'));
        const discountAmount = discountableItems.reduce(
          (acc, item) => acc + item.price * item.quantity * 0.15,
          0
        );

        setDiscountAmount(discountAmount);
        setDiscountApplied(true);
        setError('');
      } else {
        setError(data.message || 'Invalid or already used discount code.');
      }
    } catch (err) {
      setError('Failed to validate discount code. Try again later.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const finalAmount = discountApplied ? total - discountAmount : total;
    const amountToCharge = finalAmount * 100;

    try {
      const response = await fetch('https://cakvx0v4ng.execute-api.us-east-1.amazonaws.com/default/mellowStripePaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: amountToCharge,
          customerInfo,
          cartItems,
        }),
      });

      const { clientSecret, error } = await response.json();

      if (error) {
        setError(error);
        setLoading(false);
        return;
      }

      const { error: stripeError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: customerInfo.name,
            email: customerInfo.email,
            address: {
              line1: customerInfo.address,
              city: customerInfo.city,
              postal_code: customerInfo.zip,
            },
          },
        },
      });

      if (stripeError) {
        setError(stripeError.message);
      } else {
        setShowReceipt(true);
        await sendEmailReceipt(customerInfo.email, customerInfo.name);
      }
    } catch (err) {
      setError('Payment failed. Please try again.');
    }

    setLoading(false);
  };

  const handleReceiptClose = () => {
    setShowReceipt(false);
    clearCart();
    onClose();
  };

  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handlePrintReceipt = async () => {
    const canvas = await html2canvas(receiptRef.current);
    const dataUrl = canvas.toDataURL('image/png');

    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<img src="${dataUrl}" style="width:100%;height:auto;" />`);
    newWindow.print();
  };

  return (
    <div className="checkout-modal-overlay">
      <div className="checkout-modal">
        {/* Back Button Addition */}
        <button className="back-btn" onClick={handleBackToCartClick}>
          <span className="back-btn-icon">←</span>
          <span className="back-btn-text">Back to Cart</span>
        </button>
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h2 className="checkout-title">Checkout</h2>

        {/* Rest of your component JSX remains exactly the same */}
        <form className="checkout-form" onSubmit={handleSubmit}>
          <div className="checkout-form-section">
            <h3 className="section-title">Shipping Information</h3>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={customerInfo.name}
              onChange={handleChange}
              className="checkout-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={customerInfo.email}
              onChange={handleChange}
              className="checkout-input"
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={customerInfo.address}
              onChange={handleChange}
              className="checkout-input"
              required
            />
            <div className="input-row">
              <input
                type="text"
                name="city"
                placeholder="City"
                value={customerInfo.city}
                onChange={handleChange}
                className="checkout-input"
                required
              />
              <input
                type="text"
                name="zip"
                placeholder="ZIP Code"
                value={customerInfo.zip}
                onChange={handleChange}
                className="checkout-input"
                required
              />
            </div>
          </div>

          <div className="checkout-form-section">
            <h3 className="section-title">Payment Information</h3>
            <CardElement className="card-element" />
          </div>

          <div className="checkout-form-section">
            <div className="discount-section">
              <input
                type="text"
                placeholder="Discount Code"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                className="checkout-input discount-input"
              />
              <button
                type="button"
                onClick={validateDiscountCode}
                disabled={discountApplied}
                className="checkout-apply-btn"
              >
                Apply
              </button>
            </div>
            {discountApplied && (
              <div className="checkout-summary-line discount">
                <span>Discount Applied:</span>
                <span>-${discountAmount.toFixed(2)}</span>
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
          </div>

          <div className="checkout-section">
            <h3 className="checkout-summary-title">Order Summary</h3>
            <div className="checkout-items-list">
              {cartItems.map((item) => (
                <div key={`${item.id}-${item.color}`} className="checkout-item">
                  <div className="checkout-item-details">
                    <img src={item.image} alt={item.title} className="checkout-item-img" />
                    <div className="checkout-item-info">
                      <h4 className="checkout-item-title">
                        {item.title} {item.color ? `(${item.color})` : ''}
                      </h4>
                      <p className="checkout-item-quantity">
                        ${item.price.toFixed(2)} × {item.quantity}
                      </p>
                    </div>
                  </div>
                  <span className="checkout-item-total">
                    ${(item.price * item.quantity).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>

            <div className="checkout-summary-container">
              <div className="checkout-summary-line">
                <span>Merchandise Subtotal:</span>
                <span>${subtotal.toFixed(2)}</span>
              </div>
              <div className="checkout-summary-line">
                <span>Shipping & Handling:</span>
                <span>${shippingFee.toFixed(2)}</span>
              </div>
              <div className="checkout-summary-line subtotal">
                <span>Subtotal before fees:</span>
                <span>${(subtotal + shippingFee).toFixed(2)}</span>
              </div>
              <div className="checkout-summary-line fee">
                <span>Processing Fee (5%):</span>
                <span>${processingFee.toFixed(2)}</span>
              </div>
              {discountApplied && (
                <div className="checkout-summary-line discount">
                  <span>Discount Applied:</span>
                  <span>-${discountAmount.toFixed(2)}</span>
                </div>
              )}
              <div className="checkout-summary-line total">
                <span>Total:</span>
                <span>
                  ${discountApplied
                    ? (total - discountAmount).toFixed(2)
                    : total.toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="checkout-submit-btn"
            disabled={!stripe || loading}
          >
            {loading ? 'Processing...' : 'Pay Now'}
          </button>
        </form>

        {showReceipt && (
          <div className="receipt-modal">
            <div className="receipt-content" ref={receiptRef}>
              <img
                src={printerIcon}
                alt="Print Receipt"
                className="receipt-print-icon"
                onClick={handlePrintReceipt}
              />
              <h2>Thank You for Your Purchase!</h2>
              <p>Your payment was successful.</p>
              <h3>Order Summary</h3>
              <ul className="receipt-cart-summary">
                {cartItems.map((item) => (
                  <li key={item.id} className="receipt-cart-item">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="receipt-item-img"
                      onClick={() => handleImageClick(item.image)}
                    />
                    <span className="item-name">{item.title} x {item.quantity}</span>
                    <span className="item-price">${(item.price * item.quantity).toFixed(2)}</span>
                  </li>
                ))}
              </ul>
              <p className="receipt-total">
                Total Paid: $
                {discountApplied
                  ? (total - discountAmount).toFixed(2)
                  : total.toFixed(2)}
              </p>
              <button className="receipt-close-btn" onClick={handleReceiptClose}>
                Close and Return to Shop
              </button>
            </div>
          </div>
        )}

        {fullscreenImage && (
          <div className="fullscreen-image-overlay" onClick={closeFullscreenImage}>
            <img src={fullscreenImage} alt="Enlarged product" />
            <button className="close-fullscreen-btn" onClick={closeFullscreenImage}>
              &times;
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default function StripeCheckoutWrapper(props) {
  return (
    <Elements stripe={stripePromise}>
      <Checkout {...props} />
    </Elements>
  );
}