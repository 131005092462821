import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import '../stylings/Home.css';
import aboutUsImage from '../imgs/ryanAndRory.jpg';
import hydroLidBlack8ozLogo from '../productImgs/HydroLid 8oz Black + Logo.png';
import hydroLidBlue8oz from '../productImgs/HydroLid 8oz + Logo.png'; // Missing import added
import hydroLidGreen8oz from '../productImgs/HydroLid 8oz Green + Logo.png'; // Missing import added
import hydroLidRed8oz from '../productImgs/RED 8oz.png'; // Missing import added
import hydroLidBlue16oz from '../productImgs/HydroLid 16 oz Blue final.png';
import hydroLidGreen16oz from '../productImgs/HydroLid 16oz Green Final.png';
import hydroLidRed16oz from '../productImgs/HydroLid 16oz Red Final.png';
import hydroLidBlack16oz from '../productImgs/HydroLid Black 16oz final.png';
import standaloneBlack from '../productImgs/Hydrolid Blk w LOGO.png';
import standaloneBlue from '../productImgs/Blue lid + logo.png'; // Missing import added
import standaloneGreen from '../productImgs/HydroLid Green and Logo.jpg'; // Missing import added
import standaloneRed from '../productImgs/HydroLid Red + Logo.png'; // Missing import added
import { CartContext } from '../context/CartContext';
import Notification from './Notification';
import Modal from './Modal';

import mellowCustomerReviewStandIn01 from '../imgs/mellowCustomerReviewStandIn_01.png';
import mellowCustomerReviewStandIn02 from '../imgs/mellowCustomerReviewStandIn_02.png';

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    reason: '',
    subscribe: false,
  });

  const [selectedProduct, setSelectedProduct] = useState(null);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  // Updated product array with detailed descriptions and options from Modal.jsx
  const products = [
    {
      id: 1,
      title: '8oz HydroLid Unit',
      description: `Designed to hold 60% Relative Humidity.
                    Easily activated with 10-15 drops of water.
                    Rechargeable for up to 2 years at a time.
                    Odor Proof.`,
      price: 27.99,
      image: hydroLidBlack8ozLogo,
      category: '8oz HydroLid',
      colorOptions: [
        { name: 'Blue', colorCode: '#4a90e2', image: hydroLidBlue8oz },
        { name: 'Black', colorCode: '#333333', image: hydroLidBlack8ozLogo },
        { name: 'Green', colorCode: '#4caf50', image: hydroLidGreen8oz },
        { name: 'Red', colorCode: '#e53935', image: hydroLidRed8oz },
      ],
    },
    {
      id: 2,
      title: '16oz HydroLid Unit',
      description: `Designed to hold 60% Relative Humidity.
                    Easily activated with 10-15 drops of water.
                    Rechargeable for up to 2 years at a time.
                    Odor Proof.`,
      price: 29.99,
      image: hydroLidBlack16oz,
      category: '16oz HydroLid',
      colorOptions: [
        { name: 'Blue', colorCode: '#4a90e2', image: hydroLidBlue16oz },
        { name: 'Green', colorCode: '#4caf50', image: hydroLidGreen16oz },
        { name: 'Red', colorCode: '#e53935', image: hydroLidRed16oz },
        { name: 'Black', colorCode: '#333333', image: hydroLidBlack16oz },
      ],
    },
    {
      id: 3,
      title: 'Standalone Black HydroLid',
      description: `Attachable to any wide-mouth Mason or Kerr Jar.
                    Designed to hold 60% Relative Humidity.
                    Easily activated with 10-15 drops of water.
                    Rechargeable for up to 2 years at a time.
                    Odor Proof.`,
      price: 22.99,
      image: standaloneBlack,
      category: 'Standalone HydroLid',
      colorOptions: [
        { name: 'Blue', colorCode: '#4a90e2', image: standaloneBlue },
        { name: 'Black', colorCode: '#333333', image: standaloneBlack },
        { name: 'Green', colorCode: '#4caf50', image: standaloneGreen },
        { name: 'Red', colorCode: '#e53935', image: standaloneRed },
      ],
    },
  ];
  

  const [notification, setNotification] = useState({
    message: '',
    visible: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://k55dgmsolc.execute-api.us-east-1.amazonaws.com/default/contactFormSub', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setNotification({
          message: 'Form submitted successfully!',
          visible: true,
        });

        // Reset form fields after successful submission
        setFormData({
          name: '',
          email: '',
          message: '',
          reason: '',
          subscribe: false,
        });
      } else {
        setNotification({
          message: `Error: ${data.error || 'Submission failed'}`,
          visible: true,
        });
      }
    } catch (error) {
      setNotification({
        message: `Error: ${error.message}`,
        visible: true,
      });
    }

    // Hide notification after a few seconds
    setTimeout(() => {
      closeNotification();
    }, 3000);
  };

  const handleAddToCart = (product) => {
    addToCart(product);
    setNotification({
      message: `${product.title} added to cart!`,
      visible: true,
    });

    setTimeout(() => {
      closeNotification();
    }, 3000);
  };

  const closeNotification = () => {
    setNotification({ message: '', visible: false });
  };

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <>
      <Header />
      <div className="home-container">
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="hero-title">Mellow Heights</h1>
            <p className="hero-description">
              Elevate your lifestyle with HydroLid — keeping herbs, spices, and more fresh.
            </p>
            <button 
              className="hero-button" 
              onClick={() => navigate('/shop')}
            >
              Shop Now
            </button>
          </div>
        </div>

        <div className="features-section">
          <div className="feature">
            <h2>Humidity Control</h2>
            <p>
              Keep your ingredients at their best with our advanced humidity control technology.
            </p>
          </div>
          <div className="feature">
            <h2>Versatile Design</h2>
            <p>
              Compatible with any wide-mouth mason jar, HydroLid is perfect for herbs, spices, and baking ingredients.
            </p>
          </div>
          <div className="feature">
            <h2>Sustainable</h2>
            <p>
              Our products are reusable, rechargeable, and designed to last, supporting a sustainable lifestyle.
            </p>
          </div>
        </div>

        {/* Product Showcase Section */}
        <div className="product-showcase">
          <h2 className="showcase-title">Explore Our Products</h2>
          <div className="product-grid">
            {products.map((product) => (
              <div key={product.id} className="product-card">
                <img 
                  src={product.image} 
                  alt={product.title} 
                  className="product-image" 
                  onClick={() => openModal(product)}
                />
                <h3 className="product-title">{product.title}</h3>
                <p className="product-description">{product.description}</p>
                <p className="product-price">${product.price.toFixed(2)}</p>
                <button
                  className="product-button"
                  onClick={() => handleAddToCart(product)}
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Render the Modal if a product is selected */}
        {selectedProduct && (
          <Modal
            product={selectedProduct}
            onClose={closeModal}
          />
        )}

        {/* About Us Section */}
        <div id="about-section" className="about-section">
          <h2 className="about-title">About Mellow Heights</h2>
          <div className="about-content">
            <img src={aboutUsImage} alt="About Mellow Heights" className="about-image" />
            <div className="about-text">
              <p className="about-description">
                Mellow Heights was founded with a single vision: to preserve the natural essence of your ingredients.
                Our HydroLid products maintain ideal humidity levels, keeping herbs, spices, sugars, and baking essentials
                at peak freshness, all while promoting a sustainable lifestyle.
              </p>
              <p className="about-mission">
                We believe in simplicity, innovation, and quality. Our products are thoughtfully designed to be versatile
                and eco-friendly, enhancing both your kitchen and your lifestyle. At Mellow Heights, we are committed to
                delivering a freshness experience that speaks for itself.
              </p>
            </div>
          </div>
        </div>

        {/* Product Reviews Section */}
        <div className="reviews-section">
          <h2 className="reviews-title">What Our Customers Are Saying</h2>
          <div className="reviews-grid">
            {[
              {
                id: 1,
                name: 'Samantha Green',
                reviewTitle: 'Absolutely Love It!',
                message: 'The HydroLid has kept my herbs fresh longer than anything else I have tried.',
                image: mellowCustomerReviewStandIn01,
              },
              {
                id: 2,
                name: 'James White',
                reviewTitle: 'Perfect for Everyday Use!',
                message: 'I use the HydroLid for my spices and it works great. Keeps everything fresh!',
                image: mellowCustomerReviewStandIn02,
              },
            ].map((review) => (
              <div key={review.id} className="review-card">
                <img src={review.image} alt={review.name} className="review-image" />
                <div className="review-content">
                  <h3 className="review-title">{review.reviewTitle}</h3>
                  <p className="review-message">"{review.message}"</p>
                  <p className="review-author">- {review.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="contact-section">
          <h2 className="contact-title">Get in Touch</h2>
          <p className="contact-subtitle">We’d love to hear from you. Let’s connect!</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                className="contact-input apple-style-input"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                className="contact-input apple-style-input"
                required
              />
            </div>
            <select
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              className="contact-select apple-style-select"
              required
            >
              <option value="" disabled>Reason for Contact</option>
              <option value="support">Customer Support</option>
              <option value="feedback">Product Feedback</option>
              <option value="review">Leave a Product Review</option>
              <option value="partnership">Business Partnership</option>
              <option value="other">Other</option>
            </select>
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              className="contact-textarea apple-style-textarea"
              required
            />
            <div className="newsletter-checkbox">
              <label htmlFor="subscribe-checkbox" className="apple-checkbox-label">
                Subscribe to our newsletter
              </label>
              <input
                type="checkbox"
                name="subscribe"
                checked={formData.subscribe}
                onChange={handleChange}
                className="apple-style-checkbox"
                id="subscribe-checkbox"
              />
            </div>
            <button type="submit" className="contact-button apple-style-button">
              Submit
            </button>
          </form>
        </div>

        {/* Notification Component */}
        <Notification
          message={notification.message}
          visible={notification.visible}
          onClose={closeNotification}
        />
      </div>
    </>
  );
};

export default Home;
