import React, { createContext, useState, useEffect } from 'react';

// Create Context
export const CartContext = createContext();

// Cart Provider Component
export const CartProvider = ({ children }) => {
  const FLAT_SHIPPING_FEE = 5.99; // Flat shipping fee

  const [cartItems, setCartItems] = useState(() => {
    // Retrieve cart state from localStorage, if available
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Save cart state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  // Add item to cart
  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item.id === product.id && item.color === product.color
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === product.id && item.color === product.color
            ? {
                ...item,
                quantity: item.quantity + (product.quantity || 1),
                totalPrice: (item.quantity + (product.quantity || 1)) * item.price,
              }
            : item
        );
      } else {
        return [
          ...prevItems,
          { ...product, quantity: product.quantity || 1, totalPrice: product.price * (product.quantity || 1) },
        ];
      }
    });
  };

  // Remove item from cart by id and color
  const removeFromCart = (productId, color) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => !(item.id === productId && item.color === color))
    );
  };

  // Update item quantity in cart by id and color
  const updateQuantity = (productId, color, newQuantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId && item.color === color
          ? { ...item, quantity: newQuantity, totalPrice: item.price * newQuantity }
          : item
      )
    );
  };

  // Clear the cart items and reset the localStorage count
  const clearCart = () => {
    setCartItems([]); // Clears cart items
    localStorage.removeItem('cart'); // Clears the saved cart in localStorage
  };

  // Calculate total amount (subtotal only)
  const totalAmount = cartItems.reduce(
    (total, item) => total + (item.totalPrice || 0), 0
  );

  // Calculate total amount including the flat shipping fee
  const totalAmountWithShipping = cartItems.length > 0 ? totalAmount + FLAT_SHIPPING_FEE : totalAmount;

  // Notify user when an item is added to the cart
  const showAddToCartNotification = (product) => {
    alert(`Successfully added ${product.quantity} x ${product.title} in ${product.color} to your cart!`);
  };

  return (
    <CartContext.Provider value={{ 
      cartItems, 
      addToCart, 
      removeFromCart, 
      updateQuantity, 
      totalAmount,
      totalAmountWithShipping, // Provide total with shipping
      clearCart,  // Add clearCart to context
      showAddToCartNotification 
    }}>
      {children}
    </CartContext.Provider>
  );
};
