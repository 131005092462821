import React from 'react';
import '../stylings/OrderDetailsModal.css';

const OrderDetailsModal = ({ order, onClose }) => {
  if (!order) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Order Details</h2>
          <button className="close-btn" onClick={onClose}>&times;</button>
        </div>
        
        <div className="modal-body">
          <div className="order-section">
            <h3>Order Information</h3>
            <div className="info-grid">
              <div className="info-item">
                <label>Order ID</label>
                <span>#{order.order_id}</span>
              </div>
              <div className="info-item">
                <label>Order Date</label>
                <span>{new Date(order.order_date).toLocaleString()}</span>
              </div>
              <div className="info-item">
                <label>Total Amount</label>
                <span>${parseFloat(order.total_amount).toFixed(2)}</span>
              </div>
              <div className="info-item">
                <label>Discount Code</label>
                <span>{order.discount_code || 'None'}</span>
              </div>
            </div>
          </div>

          <div className="order-section">
            <h3>Customer Details</h3>
            <div className="info-grid">
              <div className="info-item">
                <label>Name</label>
                <span>{order.full_name}</span>
              </div>
              <div className="info-item">
                <label>Email</label>
                <span>{order.email}</span>
              </div>
              <div className="info-item">
                <label>Address</label>
                <span>{order.address}</span>
              </div>
              <div className="info-item">
                <label>City</label>
                <span>{order.city}</span>
              </div>
              <div className="info-item">
                <label>Zip Code</label>
                <span>{order.zip_code}</span>
              </div>
            </div>
          </div>

          <div className="order-section">
            <h3>Items</h3>
            <div className="items-list">
              {Array.isArray(order.items) ? 
                order.items.map((item, index) => (
                  <div key={index} className="order-item-detail">
                    <div className="item-info">
                      <span className="item-name">{item.title}</span>
                      <span className="item-quantity">Qty: {item.quantity}</span>
                    </div>
                    <span className="item-price">${item.totalPrice.toFixed(2)}</span>
                  </div>
                )) : 
                JSON.parse(order.items).map((item, index) => (
                  <div key={index} className="order-item-detail">
                    <div className="item-info">
                      <span className="item-name">{item.title}</span>
                      <span className="item-quantity">Qty: {item.quantity}</span>
                    </div>
                    <span className="item-price">${item.totalPrice.toFixed(2)}</span>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsModal;