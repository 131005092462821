import React, { useContext, useRef, useEffect, useState } from 'react';
import '../stylings/Modal.css';
import { CartContext } from '../context/CartContext';
import { products } from './Shop';

import standaloneBlack from '../productImgs/Hydrolid Blk w LOGO.png';
import standaloneBlue from '../productImgs/Blue lid + logo.png';
import standaloneGreen from '../productImgs/HydroLid Green and Logo.jpg';
import standaloneRed from '../productImgs/HydroLid Red + Logo.png';

import hydroLidBlue8oz from '../productImgs/HydroLid 8oz + Logo.png';
import hydroLidBlack8oz from '../productImgs/HydroLid 8oz Black + Logo.png';
import hydroLidGreen8oz from '../productImgs/HydroLid 8oz Green + Logo.png';
import hydroLidRed8oz from '../productImgs/RED 8oz.png';

import hydroLidBlue16oz from '../productImgs/HydroLid 16 oz Blue final.png';
import hydroLidBlack16oz from '../productImgs/HydroLid Black 16oz final.png';
import hydroLidGreen16oz from '../productImgs/HydroLid 16oz Green Final.png';
import hydroLidRed16oz from '../productImgs/HydroLid 16oz Red Final.png';

const colorOptions = [
  { name: 'Black', colorCode: '#333333', image: standaloneBlack },
  { name: 'Blue', colorCode: '#4a90e2', image: standaloneBlue },
  { name: 'Green', colorCode: '#4caf50', image: standaloneGreen },
  { name: 'Red', colorCode: '#e53935', image: standaloneRed },
];

const pack8ozColorOptions = [
  { name: 'Blue', colorCode: '#4a90e2', image: hydroLidBlue8oz },
  { name: 'Black', colorCode: '#333333', image: hydroLidBlack8oz },
  { name: 'Green', colorCode: '#4caf50', image: hydroLidGreen8oz },
  { name: 'Red', colorCode: '#e53935', image: hydroLidRed8oz },
];

const pack16ozColorOptions = [
  { name: 'Blue', colorCode: '#4a90e2', image: hydroLidBlue16oz },
  { name: 'Black', colorCode: '#333333', image: hydroLidBlack16oz },
  { name: 'Green', colorCode: '#4caf50', image: hydroLidGreen16oz },
  { name: 'Red', colorCode: '#e53935', image: hydroLidRed16oz },
];

const Modal = ({ product, onClose, initialColor }) => {
  const { addToCart, showAddToCartNotification } = useContext(CartContext);
  const carouselRef = useRef(null);
  const clonedProducts = [...products, ...products, ...products];
  const [selectedColor, setSelectedColor] = useState(
    colorOptions.find((color) => color.name === initialColor) || colorOptions[0]
  );
  const [quantity, setQuantity] = useState(1);

  const [modalProductDetails, setModalProductDetails] = useState({
    title: product.title,
    description:
      product.category === 'Standalone HydroLid'
        ? `Attachable to any wide-mouth Mason or Kerr Jar.
           Designed to hold 60% Relative Humidity.
           Easily activated with 10-15 drops of water.
           Rechargeable for up to 2 years at a time.
           Odor Proof.`
        : product.category === '8oz HydroLid'
        ? `Designed to hold 60% Relative Humidity.
           Easily activated with 10-15 drops of water.
           Rechargeable for up to 2 years at a time.
           Odor Proof.`
        : product.category === '16oz HydroLid'
        ? `Designed to hold 60% Relative Humidity.
           Easily activated with 10-15 drops of water.
           Rechargeable for up to 2 years at a time.
           Odor Proof.`
        : product.category === 'Packs' && product.title === '3-Pack Standalone HydroLid'
        ? `Save with our 3-Pack Standalone HydroLids.
           Each lid fits wide-mouth jars, holds 60% humidity, and recharges for 2 years.`
        : product.category === 'Packs' && product.title === '3-Pack 8oz HydroLid'
        ? `Enjoy great savings with our 3-Pack of 8oz HydroLids.
           Designed for smaller storage, each lid holds 60% humidity, activates with a few drops of water, and recharges for up to 2 years.
           Odor-proof and perfect for preserving freshness in your wide-mouth jars.`
        : product.category === 'Packs' && product.title === '3-Pack 16oz HydroLid'
        ? `Get more value with our 3-Pack of 16oz HydroLids.
           Ideal for larger storage needs, each lid maintains 60% humidity, activates with a few drops of water, and recharges for up to 2 years.
           Odor-proof and excellent for big batches in wide-mouth jars.`
        : product.description,
    price: product.price,
    originalPrice: product.originalPrice,
    image: product.image,
  });

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const resetScroll = () => {
        if (carousel.scrollLeft <= 0) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        } else if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        }
      };

      carousel.addEventListener('scroll', resetScroll);
      carousel.scrollLeft = carousel.scrollWidth / 3;

      return () => {
        carousel.removeEventListener('scroll', resetScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (initialColor) {
      const color = colorOptions.find((c) => c.name === initialColor);
      if (color) setSelectedColor(color);
    }
  }, [initialColor]);

  const handleColorChange = (color) => {
    setSelectedColor(color);

    if (product.title === '3-Pack Standalone HydroLid') {
      setModalProductDetails((prevDetails) => ({
        ...prevDetails,
        image: color.image,
      }));
    } else if (product.title === '3-Pack 8oz HydroLid') {
      setModalProductDetails((prevDetails) => ({
        ...prevDetails,
        image: pack8ozColorOptions.find((c) => c.name === color.name)?.image || prevDetails.image,
      }));
    } else if (product.title === '3-Pack 16oz HydroLid') {
      setModalProductDetails((prevDetails) => ({
        ...prevDetails,
        image: pack16ozColorOptions.find((c) => c.name === color.name)?.image || prevDetails.image,
      }));
    } else {
      const updatedProduct = products.find(
        (p) =>
          p.title.toLowerCase().includes(color.name.toLowerCase()) &&
          p.category === product.category
      );

      if (updatedProduct) {
        setModalProductDetails({
          title: updatedProduct.title,
          description: updatedProduct.description || modalProductDetails.description,
          price: updatedProduct.price,
          originalPrice: updatedProduct.originalPrice,
          image: updatedProduct.image,
        });
      }
    }
  };

  const handleAddToCartClick = () => {
    const itemToAdd = {
      ...product,
      title: modalProductDetails.title,
      description: modalProductDetails.description,
      color: selectedColor.name || product.color,
      image: modalProductDetails.image,
      quantity,
      totalPrice: modalProductDetails.price * quantity,
    };

    addToCart(itemToAdd);
    showAddToCartNotification(itemToAdd);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <img
          src={modalProductDetails.image}
          alt={`${modalProductDetails.title} - ${selectedColor.name}`}
          className="modal-image"
        />

        {/* Color Selector */}
        <div className="color-selector">
          <div className="color-options">
            {(product.title === '3-Pack 8oz HydroLid'
              ? pack8ozColorOptions
              : product.title === '3-Pack 16oz HydroLid'
              ? pack16ozColorOptions
              : colorOptions
            ).map((color) => (
              <button
                key={color.name}
                style={{ backgroundColor: color.colorCode }}
                className={`color-option ${
                  selectedColor.name === color.name ? 'active' : ''
                }`}
                onClick={() => handleColorChange(color)}
              />
            ))}
          </div>
        </div>

        <div className="modal-details">
          <h3 className="modal-title">{modalProductDetails.title}</h3>
          <p className="modal-description">{modalProductDetails.description}</p>

          {modalProductDetails.originalPrice ? (
            <>
              <p className="modal-price-message">Special Offer – Don't miss out!</p>
              <div className="modal-price-section">
                <p className="modal-original-price">
                  ${modalProductDetails.originalPrice.toFixed(2)}
                </p>
                <p className="modal-price">
                  ${modalProductDetails.price.toFixed(2)}
                </p>
                <p className="modal-discount">
                  Save{' '}
                  {Math.round(
                    ((modalProductDetails.originalPrice -
                      modalProductDetails.price) /
                      modalProductDetails.originalPrice) *
                      100
                  )}
                  %
                </p>
              </div>
            </>
          ) : (
            <p className="modal-price">${modalProductDetails.price.toFixed(2)}</p>
          )}

          <div className="quantity-add">
            <div className="quantity-selector">
              <label className="quantity-label">Qty:</label>
              <input
                type="number"
                className="quantity-input apple-style-input"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
              />
            </div>
            <button className="add-to-cart-btn" onClick={handleAddToCartClick}>
              Add to Cart
            </button>
          </div>
        </div>

        {/* Product Carousel */}
        <div className="carousel-container">
          <h3 className="carousel-title">You May Also Like</h3>
          <div className="carousel" ref={carouselRef}>
            {clonedProducts.map((carouselProduct, index) => (
              <div key={`${carouselProduct.id}-${index}`} className="carousel-item">
                <img
                  src={carouselProduct.image}
                  alt={carouselProduct.title}
                  className="carousel-item-image"
                />
                <h4 className="carousel-item-title">{carouselProduct.title}</h4>
                <p className="carousel-item-price">
                  ${carouselProduct.price.toFixed(2)}
                </p>
                <button
                  className="carousel-add-btn"
                  onClick={() => {
                    addToCart({
                      ...carouselProduct,
                      quantity: 1,
                      color: selectedColor.name,
                      image: modalProductDetails.image,
                    });
                    showAddToCartNotification(carouselProduct);
                  }}
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
